import React, { FC, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import NavOption from './NavOption';
import { Container } from './generic/View';
import classNames from 'classnames';
import { DeepExtractType } from 'ts-deep-extract-types';
import Logo from '../assets/svgs/logo.svg';

type NavElements = GatsbyTypes.Maybe<
  DeepExtractType<
    GatsbyTypes.HeaderNavItemsQuery,
    ['site', 'siteMetadata', 'navItemsHeader']
  >
>;

const DesktopNav: FC<{
  navElements: NavElements;
}> = ({ navElements }) => {
  return (
    <nav className="hidden md:block">
      {navElements
        ? navElements.map((elem, index) => {
            return (
              <NavOption
                key={index}
                title={elem?.title || ''}
                to={elem?.path || ''}
                parent="header"
              />
            );
          })
        : null}
    </nav>
  );
};

const Bar: FC<{ toggled: boolean }> = ({ toggled }) => {
  return (
    <div
      className={classNames(
        'h-[3px] w-[29px] my-[6px] bg-dark-gray transition-colors duration-150',
        toggled && 'bg-white m-0'
      )}
    />
  );
};

const MobileMenuHamburger: FC<{
  toggled: boolean;
  toggleMenu: () => void;
  className: string;
}> = ({ toggleMenu, toggled, className }) => {
  return (
    <button
      className={classNames(toggled && 'menu-toggle--state-toggled', className)}
      onClick={() => toggleMenu()}
    >
      <Bar toggled={toggled} />
      <Bar toggled={toggled} />
      <Bar toggled={toggled} />
    </button>
  );
};

const Overlay: FC<{ untoggleMenu: () => void }> = ({ untoggleMenu }) => {
  return (
    <div
      className="fixed left-0 top-0 w-full h-full z-20 backdrop-blur-sm"
      onClick={() => untoggleMenu()}
    />
  );
};

const MobileNav: FC<{
  navElements: NavElements;
}> = ({ navElements }) => {
  return (
    <nav className="flex flex-col">
      {navElements
        ? navElements.map((elem, index) => {
            return (
              <Link
                key={index}
                to={elem?.path || ''}
                className="font-sans text-base my-3 uppercase font-medium"
                activeClassName="text-green"
              >
                {elem?.title}
              </Link>
            );
          })
        : null}
    </nav>
  );
};

const MobileMenuWrap: FC<{
  navElements: NavElements;
  toggled: boolean;
  toggleMenu: () => void;
}> = ({ navElements, toggleMenu, toggled }) => {
  return (
    <div
      className={classNames(
        'flex md:hidden flex-col justify-center text-center fixed w-64 h-full translate-x-full top-0 right-0 bg-dark-blue shadow-xl text-white transition-transform duration-150 z-40',
        toggled && 'translate-x-0'
      )}
    >
      <div className="absolute w-full top-0 flex items-center h-20 justify-between flex-row">
        <MobileMenuHamburger
          toggleMenu={() => toggleMenu()}
          toggled={true}
          className="absolute right-5"
        />
      </div>
      <MobileNav navElements={navElements} />
    </div>
  );
};

const Header: FC = () => {
  const { site } = useStaticQuery<GatsbyTypes.HeaderNavItemsQuery>(
    graphql`
      query HeaderNavItems {
        site {
          siteMetadata {
            navItemsHeader {
              title
              path
            }
          }
        }
      }
    `
  );

  const [mobileMenuIsToggled, setMobileMenuIsToggled] = useState(false);

  const showOverlay = mobileMenuIsToggled ? (
    <Overlay untoggleMenu={() => setMobileMenuIsToggled(false)} />
  ) : null;

  return (
    <>
      {showOverlay}
      <header>
        <Container className="flex items-center h-20 justify-between flex-row md:h-30">
          <Link to="/" className="h-8 md:h-11 w-auto svg-wrapper">
            <Logo />
          </Link>
          <DesktopNav navElements={site?.siteMetadata?.navItemsHeader} />
          <MobileMenuWrap
            navElements={site?.siteMetadata?.navItemsHeader}
            toggleMenu={() => setMobileMenuIsToggled(!mobileMenuIsToggled)}
            toggled={mobileMenuIsToggled}
          />
          <MobileMenuHamburger
            toggleMenu={() => setMobileMenuIsToggled(true)}
            toggled={false}
            className="block md:hidden absolute right-0 top-1/2 -translate-y-1/2"
          />
        </Container>
      </header>
    </>
  );
};

export default Header;
