import React, { FC } from 'react';
import Content from './RichContent';
import { Container } from './generic/View';
import NavOption from './NavOption';

const Footer: FC<{ richText: string }> = ({ richText }) => {
  return (
    <footer
      className={`bg-dark-gray text-white
                  py-12 md:py-23`}
    >
      <Container className="flex flex-col justify-between">
        <Content markdown={richText} className="paragraph-extra-large mb-mm" />
        <div className="flex flex-col md:flex-row">
          <div className="mb-ms md:mb-0">
            <NavOption
              title="imprint"
              to="/imprint"
              parent="footer"
              className="mr-28"
            />
          </div>
          <div>
            <NavOption title="privacy policy" to="/privacy" parent="footer" />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
