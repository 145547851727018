import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import useSiteMetadata from './SiteMetadata';

const TemplateWrapper: FC<{
  pageTitle: string;
  metaTagDescription: string;
}> = ({ children, pageTitle, metaTagDescription }) => {
  const siteMetadata = useSiteMetadata();

  const title = `${
    siteMetadata?.title ? siteMetadata?.title + ' - ' : ''
  }${pageTitle}`;

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={metaTagDescription} />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaTagDescription} />
      </Helmet>
      <div className="w-full xl:w-[90rem] xl:m-auto">
        <Header />
        {children}
      </div>
    </>
  );
};

export default TemplateWrapper;
