import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

const NavOption: FC<{
  title: string;
  to: string;
  className?: string;
  parent: 'header' | 'footer';
}> = ({ title, to, className, parent }) => {
  let parentBasedStyles, parentBasedActiveStyle;
  if (parent === 'header') {
    parentBasedStyles =
      'text-black uppercase font-medium mr-7.5 md:mr-36 md:last:mr-0';
    parentBasedActiveStyle = 'after:bg-dark-blue';
  } else {
    parentBasedStyles = `text-white font-normal 
                        after:block after:absolute after:h-0.5 after: after:-bottom-2.5 
                        after:w-full after:bg-white hover:after:bg-green after:transition-colors`;
    parentBasedActiveStyle = 'text-green after:bg-green';
  }

  return (
    <Link
      to={to}
      className={classNames(
        `font-sans relative inline-block transition-colors
                  text-sm md:text-lg
                  leading-4 md:leading-5
                hover:text-green`,
        className,
        parentBasedStyles
      )}
      activeClassName={classNames(
        `after:block after:absolute after:h-0.5 after: after:-bottom-2.5 after:w-full`,
        parentBasedActiveStyle
      )}
    >
      {title}
    </Link>
  );
};

export default NavOption;
