import { graphql, useStaticQuery } from 'gatsby';
import { DeepExtractType } from 'ts-deep-extract-types';

type SiteMetadata = GatsbyTypes.Maybe<
  DeepExtractType<GatsbyTypes.SiteMetadataQuery, ['site', 'siteMetadata']>
>;

const useSiteMetadata = (): SiteMetadata => {
  const { site } = useStaticQuery<GatsbyTypes.SiteMetadataQuery>(
    graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  return site?.siteMetadata;
};

export default useSiteMetadata;
