import classNames from 'classnames';
import React, { FC } from 'react';

export const Container: FC<{ className?: string }> = ({
  className,
  children
}) => {
  return (
    <div
      className={classNames(
        `w-sm lg:w-[75rem] m-auto h-full relative`,
        className
      )}
    >
      {children}
    </div>
  );
};

export const Section: FC<{
  className?: string;
  withGradient?: boolean;
  gradientClass?: string;
}> = ({ className, withGradient, gradientClass, children }) => {
  // gradient in figma blur-[300px]
  return (
    <section
      className={classNames(
        'py-ms md:py-mm mb-2.5 md:mb-25 relative',
        className
      )}
    >
      {withGradient && (
        <div
          className={classNames(
            `absolute top-0 h-full w-full blur-3xl`,
            gradientClass
          )}
          style={{
            background:
              'linear-gradient(118.32deg, rgba(26, 90, 209, 0.25) 9.3%, rgba(149, 186, 52, 0.25) 82.79%)'
          }}
        ></div>
      )}
      {children}
    </section>
  );
};
