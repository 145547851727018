import React, { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';

const Content = ({
  html,
  markdown,
  className,
  ...props
}: {
  html?: string;
  markdown?: string;
  className?: string;
  [props: string]: any;
}): ReactElement => {
  if (html) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: html }}
        className={classNames(className)}
        {...props}
      />
    );
  }
  return (
    <ReactMarkdown
      className={classNames('rich-text-styles', className)}
      {...props}
    >
      {markdown as string}
    </ReactMarkdown>
  );
};

export default Content;
